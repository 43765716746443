import {useState, type ReactElement, useEffect, useContext} from 'react';
import {isIOS, isMobile, isSafari} from 'react-device-detect';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type MultiUser from '@refinio/one.models/lib/models/Authenticator/MultiUser.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {getDefaultSecretKeysAsBase64} from '@refinio/one.core/lib/keychain/keychain.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {isStandalone} from '@/utils/Utils.js';
import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {updateMainUser} from '@/components/onboarding/utils.js';
import {useAuthenticationState} from '@/hooks/authenticator/hooks.js';
import AddToHomeScreen from '@/gaia/components/addToHomescreen/AddToHomeScreen.js';
import LandingPageRouter from '@/gaia/root/landing/LandingPageRouter.js';
import {useNewGaiaRegistrationQuestionnaire} from '@/gaia/hooks/questionnaire/gaiaRegistration.js';
import {GaiaRegistrierung} from '@/gaia/resources/questionnaires/GaiaRegistrierung_de.js';
import IdentityExportKeysCodePrompt from '@/components/onboarding/steps/identity/IdentityExportKeysCodePrompt';

export const ONBOARDING_STATUS = {
    Loading: 0,
    Uncompleted: 1,
    exportKeys: 2,
    Completed: 3
} as const;

/**
 * Leute specific root route
 * @param props
 * @constructor
 */
export function RootRoute(props: {
    leuteModel: LeuteModel;
    questionnaireModel: QuestionnaireModel;
    one: MultiUser;
    onDone: () => void;
    app: string;
    disclaimerBody?: ReactElement | ReactElement[];
}): ReactElement {
    const [onboarding, setOnboardingStatus] = useState<number>(ONBOARDING_STATUS.Uncompleted);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const authenticationState = useAuthenticationState(props.one);
    const {setContextValue} = useContext(AppBarContext);
    const [keys, setKeys] = useState<string>('');
    const registrationQuestionnaire = useNewGaiaRegistrationQuestionnaire(
        props.questionnaireModel,
        GaiaRegistrierung
    );

    // start of onboarding / hide or show AppBar
    useEffect(() => {
        setContextValue(ov => ({...ov, hide: true}));
        return () => {
            setContextValue(ov => ({...ov, hide: false}));
        };
        // on mount only
    }, []);

    async function isRegistered(email: string): Promise<boolean> {
        if (await props.one.isRegistered(email, email)) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage(
                'gaia_registration.onboarding.messages.usernameAlreadyRegistered'
            );
            return true;
        }
        return false;
    }

    async function register(email: string, name: string, password: string, importKeys?: string) {
        const trimmedImportKeys = importKeys?.trim();

        if (trimmedImportKeys !== undefined && trimmedImportKeys !== '') {
            const [privateEncryptionKey, privateSignKey] = trimmedImportKeys.split(';');
            const trimmedPrivateEncryptionKey = privateEncryptionKey?.trim();
            const trimmedPrivateSignKey = privateSignKey?.trim();

            if (
                trimmedPrivateEncryptionKey === undefined ||
                trimmedPrivateEncryptionKey === '' ||
                trimmedPrivateSignKey === undefined ||
                trimmedPrivateSignKey === ''
            ) {
                setNotificationType(NOTIFICATION.Error);
                setNotificationMessage('errors.onBoarding.unsavedData');
                return;
            }

            try {
                await props.one.register(
                    email,
                    password,
                    email,
                    privateEncryptionKey,
                    privateSignKey
                );
            } catch (e) {
                console.error(e, {email, password, name, importKeys});
                setNotificationType(NOTIFICATION.Error);
                setNotificationMessage('gaia_registration.onboarding.messages.unsavedData');
                return;
            }

            await updateMainUser(props.leuteModel, name, email);
            setOnboardingStatus(ONBOARDING_STATUS.Completed);
            return;
        }

        try {
            await props.one.register(email, password, email);
        } catch (e) {
            console.error(e, {email, password, name, importKeys});
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('gaia_registration.onboarding.messages.unsavedData');
            return;
        }

        const ownerId = await updateMainUser(props.leuteModel, name, email);
        const secretKeys = await getDefaultSecretKeysAsBase64(ownerId);
        setKeys(`${secretKeys.secretEncryptionKey};${secretKeys.secretSignKey}`);
        setOnboardingStatus(ONBOARDING_STATUS.exportKeys);
    }

    async function login(email: string, password: string): Promise<void> {
        if (!(await props.one.isRegistered(email, email))) {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('gaia_registration.onboarding.messages.wrongUser');
            return;
        }

        try {
            await props.one.login(email, password, email);
        } catch (_e) {
            console.error(_e);
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('gaia_registration.onboarding.messages.wrongUser');
            return;
        }

        setOnboardingStatus(ONBOARDING_STATUS.Completed);
    }

    useEffect(() => {
        if (authenticationState === 'logged_in' && onboarding === ONBOARDING_STATUS.Completed) {
            props.onDone();
        }
    }, [authenticationState, onboarding]);

    if (
        (authenticationState === 'logging_in' || authenticationState === 'logging_out') &&
        onboarding === ONBOARDING_STATUS.Completed
    ) {
        return <CircularProgress className="circular-progress" size={35} />;
    }

    if (
        isIOS &&
        isMobile &&
        isSafari &&
        !isStandalone() &&
        localStorage.getItem('skipAddToHomeScreen') !== 'true'
    ) {
        return <AddToHomeScreen />;
    }

    if (onboarding === ONBOARDING_STATUS.Uncompleted) {
        return (
            <LandingPageRouter
                registrationQuestionnaire={registrationQuestionnaire}
                onRegister={register}
                isRegistered={isRegistered}
                onLogin={login}
            />
        );
    }

    if (onboarding === ONBOARDING_STATUS.exportKeys) {
        return (
            <IdentityExportKeysCodePrompt
                code={keys}
                onDone={() => setOnboardingStatus(ONBOARDING_STATUS.Completed)}
                currentStep={ONBOARDING_STATUS.exportKeys}
            />
        );
    }

    return <></>;
}
