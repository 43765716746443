import React, {useEffect, useState} from 'react';
import type {ReactElement} from 'react';

import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel';

import {StartChat} from '@/root/contacts/StartGroupChat.js';
import EddaGaiaChatModel from '@/gaia/model/EddaGaiaChatModel';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks';
import type {Person} from '@refinio/one.core/lib/recipes';
import type PatientModel from '@/model/roles/PatientModel';

export default function startGroupChat(
    patientModel: PatientModel,
    eddaGaiaChatModel: EddaGaiaChatModel,
    group: GroupModel,
    topicModel?: TopicModel,
    notifications?: Notifications
): ReactElement {
    if (
        group.name.startsWith(
            `${EddaGaiaChatModel.NAME_FORMAT.start}${EddaGaiaChatModel.NAME_FORMAT.separator}`
        )
    ) {
        return (
            <PatientChat
                patientModel={patientModel}
                eddaGaiaChatModel={eddaGaiaChatModel}
                group={group}
                topicModel={topicModel}
                notifications={notifications}
            />
        );
    } else {
        switch (group.name) {
            case 'everyone':
                return (
                    <StartChat
                        topicId={TopicModel.EVERYONE_TOPIC_ID}
                        topicModel={topicModel}
                        notifications={notifications}
                    />
                );
            case 'glue.one':
                return (
                    <StartChat
                        topicId={TopicModel.GLUE_TOPIC_ID}
                        topicModel={topicModel}
                        notifications={notifications}
                    />
                );
            default:
                return <></>;
        }
    }
}

function PatientChat(props: {
    patientModel: PatientModel;
    eddaGaiaChatModel: EddaGaiaChatModel;
    group: GroupModel;
    topicModel?: TopicModel;
    notifications?: Notifications;
    onError?: (error: Error) => void;
}): ReactElement {
    const [topicId, setTopicId] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function findPatientInGroup(
            patientModel: PatientModel,
            group: GroupModel
        ): Promise<SHA256IdHash<Person> | undefined> {
            for (const personId of group.persons) {
                if (await patientModel.isPatient(personId)) {
                    return personId;
                }
            }
            return undefined;
        }
        async function getTopicId(
            patientModel: PatientModel,
            eddaGaiaChatModel: EddaGaiaChatModel,
            group: GroupModel
        ): Promise<string | undefined> {
            const patientId = await findPatientInGroup(patientModel, group);
            if (patientId) {
                return eddaGaiaChatModel.getChatName(patientId);
            }
        }

        getTopicId(props.patientModel, props.eddaGaiaChatModel, props.group)
            .then(resultTopicId => setTopicId(resultTopicId))
            .catch(props.onError ? props.onError : console.error);
    }, [props.eddaGaiaChatModel, props.group, props.patientModel]);

    if (topicId === undefined) {
        return <></>;
    }

    return (
        <StartChat
            topicId={topicId}
            topicModel={props.topicModel}
            notifications={props.notifications}
        />
    );
}
